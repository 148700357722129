<template>
  <!-- <nav>
    <router-link to="/">Home</router-link>
  </nav> -->
  <div class="container-fluid">
    <router-view />
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: 'Inter';
  src: url('~@/assets/fonts/Inter-VariableFont_slnt,wght.ttf')
    format('truetype');
}
$list-group-active-bg: #f0f3f4;
$list-group-active-color: #141617;
$list-group-border-radius: 0.25rem;
$list-group-border-color: rgba(255, 255, 255, 0.9);
$list-group-bg: rgba(255, 255, 255, 0.9);
$font-family-base: Inter;
$font-family-size: 1rem;
$container-padding-x: 0px;
$primary: #1998ce;
$btn-color: #fff;
$input-focus-box-shadow: unset;
@import 'node_modules/bootstrap/scss/bootstrap';
</style>
