<template>
  <div class="header container-fluid d-flex fixed-top">
    <span class="text"><p>English-Berber Dictionary</p></span>
  </div>
  <div class="main container-fluid d-flex flex-column">
    <div class="title container">
      <h1 class="text">Search for Berber translation in English</h1>
    </div>

    <div class="search-form container">
      <div class="row">
        <div class="col col-md-8 ps-sm-0 pe-sm-0 pe-sm-3">
          <input
            type="text"
            autocomplete="off"
            maxlength="25"
            class="input form-control"
            id="term"
            placeholder="e.g. beautiful"
            ref="search_input"
            v-model="searchTerm"
            @keydown.enter="search(searchTerm)"
            @input="debouncedSuggest"
            @focusout="displayResults"
          />
          <div v-if="displaySuggestions" class="suggestions mt-2">
            <ul class="list-group" ref="suggestions">
              <li
                v-for="(suggestion, index) in searchSuggestions"
                :key="suggestion"
                class="list-group-item"
                @click="search(suggestion)"
                @mousedown="search(suggestion)"
                @mouseover="currentHoveredSuggestion(index, $event)"
                @mouseleave="currentHoveredSuggestion(null, $event)"
                @keydown.enter="search(suggestion)"
                :class="{ active: hoveredSuggestion === index }"
              >
                {{ suggestion }}
              </li>
            </ul>
          </div>
        </div>
        <span class="col search-button pe-sm-0">
          <button
            type="button"
            @click="search(searchTerm)"
            class="btn btn-primary"
          >
            <span class="text">Search</span>
          </button>
        </span>
      </div>
    </div>
    <div v-if="displaySearchResult" class="search-result container mt-2">
      <div v-for="item in result" :key="item" class="row">
        <section class="meaning-section">
          <p class="type">{{ item.type }}</p>
          <span
            class="meaning-entries"
            v-for="(meaning, meaning_idx) in item.meaning"
            :key="meaning.text"
          >
            <p v-if="meaning.text" class="meaning lead">
              {{ meaning.text }}
            </p>
            <div class="translation-entries container-fluid">
              <div
                v-for="(translation, translation_idx) in meaning.translations"
                :key="translation.text"
                class="translation-entry"
                :class="{
                  bottom_border:
                    meaning_idx != item.meaning.length - 1 ||
                    translation_idx != meaning.translations.length - 1
                }"
              >
                <p class="translation text">{{ translation.text }}</p>
                <p
                  v-if="translation.examples && translation.examples.length > 0"
                  class="example text"
                >
                  {{ translation.examples[0] }}
                </p>
              </div>
            </div>
          </span>
        </section>
      </div>
    </div>
  </div>
  <div class="footer container-fluid d-flex">
    <div class="text">
      <span class="copyright">Copyright © 2023. </span>
      <span>Based on Lahsen Oulhadj's work.</span>
    </div>
  </div>
  <div class="empty"></div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import backend from '@/gateways/backend.js'
import _ from 'lodash'
import debounce from 'lodash/debounce'
export default {
  name: 'HomeView',
  data() {
    return {
      searchTerm: this.$route.params.term,
      displaySearchResult: false,
      displaySuggestions: false,
      searchSuggestions: [],
      searchResult: {},
      hoveredSuggestion: -1
    }
  },
  components: {},
  computed: {
    result() {
      console.info('result() called!')
      console.info(
        'searchResult length ' + (Object.keys(this.searchResult).length === 0)
      )
      const result = []
      if (!this.searchResult || Object.keys(this.searchResult).length === 0) {
        return result
      }
      const meaning0 = this.searchResult.meaning
      if (meaning0.meanings) {
        console.info('meanings? ' + meaning0.meanings[0].meanings)
        const meaning1 = meaning0.meanings
        // meaning.
        if (meaning1.length === 1 && !meaning1[0].meanings) {
          result.push({
            type: meaning1[0].text,
            meaning: [
              {
                translations: meaning1[0].translations
              }
            ]
          })
          console.info('case 1: ' + result)
          return result
        }
        // meaning.meanings[0].meanings
        if (meaning1.length === 1 && meaning1[0].meanings) {
          result.push({
            type: meaning1[0].text,
            meaning: meaning1[0].meanings
          })
          console.info('case 2: ' + result)
          return result
        }
        const submeanings = _.map(meaning1, 'meanings')
        // meaning[0].meanings[*].meanings[*]
        if (meaning1.length > 1 && submeanings.length > 0) {
          for (const submeaning of meaning1) {
            result.push({
              type: submeaning.text,
              meaning: submeaning.meanings
            })
          }

          console.info('case 3: ' + result)
          return result
        }
      }
      console.info(result[0].meaning.translations[0].text)
      return result
    }
  },
  methods: {
    debouncedSuggest: debounce(
      function () {
        this.suggest(this.searchTerm)
      },
      350,
      {
        trailing: true
      }
    ),
    suggest() {
      if (!this.searchTerm) {
        return
      }
      this.suggestion = -1
      this.displaySearchResult = false
      setTimeout(() => {
        // console.info('fetchSuggestions being called with ' + input)
        // call /suggest?term=event.target.value
        backend
          .get('suggest?term=' + encodeURIComponent(this.searchTerm))
          .then(response => {
            this.searchSuggestions = response.data
            this.displaySuggestions = true
          })
          .catch(error => {
            console.log(error)
          })
      }, 500)
    },
    search(input) {
      if (!input) {
        return
      }
      this.$gtag.event('search', {
        event_category: 'Click',
        event_label: 'Search Button Click',
        value: input
      })
      console.info('search :' + input)
      this.$router.push({ name: 'search', query: { term: input } })
    },
    internalSearch(input) {
      if (!input) {
        console.info('input is empty!')
        return
      }
      console.info('search :' + input)
      this.searchTerm = input
      this.displaySuggestions = false
      // this.displaySearchResult = false
      backend
        .get('search?term=' + encodeURIComponent(input))
        .then(response => {
          this.searchResult = response.data
          this.displaySearchResult =
            typeof this.searchResult !== 'undefined' &&
            Object.keys(this.searchResult).length > 0
          this.$gtag.event('display_result', {
            event_category: 'backend',
            event_label: 'Search Result from Backend',
            value: this.searchResult.length === 0 ? 'empty' : 'not_empty'
          })
        })
        .catch(error => {
          this.$gtag.event('error', {
            event_category: 'backend',
            event_label: 'Error from Backend',
            value: error.message
          })
          console.log('error from be', error)
        })
    },
    displayResults() {
      this.displaySuggestions = false
      this.suggestion = -1
      this.displaySearchResult = true
    },
    currentHoveredSuggestion(index, event) {
      event.preventDefault()
      this.hoveredSuggestion = index
    }
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        console.info('param term ' + this.$route.query.term)
        this.searchTerm = this.$route.query.term
        this.internalSearch(this.searchTerm)
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>
<style lang="scss">
$font-family-base: Inter;
$font-family-size: 1rem;
$container-padding-x: 0px;
$primary: #1998ce;
$btn-color: #fff;
.border {
  border: 1px black;
}

.empty {
  display: none;
}

.header,
.footer {
  width: 100%;
  height: 3.75rem;
  padding: 1.125rem 4rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(20, 22, 23, 0.1);
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(25px);
  .text {
    color: #6e7375;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.main {
  padding-bottom: 5rem;
  background: var(--bg, #f9fbfc);
  min-height: 100vh;
  .title {
    margin-top: 5rem;
    width: 37.5rem;
    .text {
      text-align: center;
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      // issue with 600 on Safari
      font-weight: 599;
      line-height: 44px; /* 110% */
    }
  }
  .row {
    > * {
      padding: unset;
    }
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .search-form {
    margin-top: 2.5rem;
    margin-bottom: 3.56rem;
    width: 37.5rem;

    //color: #141617;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 266.667% */
    .btn {
      width: 100%;
      padding: 0.0625rem 1.625rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      .text {
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 599;
        line-height: 48px;
      }
    }
    .input {
      color: #141617;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 266.667% */
      padding-bottom: 0.6rem;
      padding-top: 0.6rem;
      &::placeholder {
        opacity: 0.3;
      }
    }
    .suggestions {
      height: 15rem;
      overflow: auto;
      //background: rgba(255, 255, 255, 0.9);
      color: #141617;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .search-result {
    p {
      margin-bottom: 0px;
    }
    .meaning-section {
      margin-bottom: 1rem;
      display: inline-flex;
      padding: 1rem 1rem 0.5rem 1rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      border-radius: 0.5rem;
      border: 1px solid rgba(20, 22, 23, 0.1);
      background: #fff;
      .meaning-entries {
        width: 100%;
        margin-bottom: 1rem;
      }
      .type {
        margin-bottom: 1rem;
        padding-bottom: 4px;
        width: 100%;
        border-bottom: 1px solid rgba(20, 22, 23, 0.1);
        color: var(--gray, #6e7375);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 599;
        line-height: 24px; /* 171.429% */
        letter-spacing: 1.12px;
        text-transform: uppercase;
      }
      .meaning {
        align-self: stretch;
        color: #141617;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 599;
        line-height: 24px; /* 133.333% */
      }
      .translation-entries {
        .text {
          color: #141617;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 133.333% */
        }
        & .example {
          color: #6e7375;
          font-style: italic;
        }
        .translation-entry {
          display: flex;
          padding: 0.5rem;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.25rem;
          align-self: stretch;
        }
        & .bottom_border {
          border-bottom: 1px solid rgba(20, 22, 23, 0.1);
        }
      }
    }
    width: 37.5rem;
    //background: rgba(255, 255, 255, 0.9);
  }
}

@media (max-width: 600px) {
  .empty {
    display: block;
    height: 5rem;
    border-bottom: unset;
    background: var(--bg, #f9fbfc);
  }
  .footer {
    z-index: -1;
    //margin-bottom: 4rem;
    background: var(--bg, #f9fbfc);
    .text {
      //done
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      .copyright::after {
        content: '\a';
        white-space: pre;
      }
    }
  }
  .header,
  .footer {
    //done
    height: 3.5rem;
    padding: 0.75rem 4rem;
    .text {
      //done
      color: var(--gray, #6e7375);
    }
  }
  .main {
    padding-bottom: 1rem;
    background: var(--bg, #f9fbfc);
    min-height: 100vh;
    .title {
      //width: 18rem;
      width: 80%;
      // done
      .text {
        // done
        color: var(--black, #141617);
        font-size: 24px;
        line-height: 28px;
      }
    }
    .search-form {
      display: flex;
      padding: unset;
      align-items: center;

      margin-top: 1.56rem;
      margin-bottom: 2.56rem;
      //width: 21.5rem;
      width: 90%;

      line-height: 3rem;
      //color: #141617;
      .row {
        //height: 100%;
        // gap: 8rem;
      }
      .search-button {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
        padding: 1rem;
      }
    }
    .search-result {
      margin-bottom: 1rem;
      //width: 21.5rem;
      width: 90%;
      //background: rgba(255, 255, 255, 0.9);
      .meaning-section {
        .meaning {
          line-height: 22.5px;
          font-size: 16px;
        }
        .translation-entries {
          .text {
            font-size: 16px;
            line-height: 22.5px; /* 133.333% */
          }
        }
      }
    }
  }
}
</style>
