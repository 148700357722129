import Vue, { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'

createApp(App)
  .use(router)
  .use(
    VueGtag,
    {
      config: {
        id: 'G-YKB04LBCR7',
        params: {
          anonymize_ip: true
        }
      }
    },
    router
  )
  .use(store)
  .mount('#app')
